import "./L2TxLogCard.css";
import { memo, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import copyToClipboard from "utils/copyToClipboard";
import { MOBILE_WIDTH } from "components/TableContainer/CustomizedMui";
import handleTxIds from "utils/handleTxIds";
import { trimStrings } from "utils/trimJsonString";
import { useGlobalIndex } from "hooks/useGlobalIndex";
import checkIssuerAlias from "utils/checkIssuerAlias";
import { ContactExecutedGql } from "../../generated/graphql";
import CopyIcon from "components/CopyIcon";
import { Fade, Tooltip } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import ActiveSwipe from "../../assets/Icons/icon-swipe-active.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Alias } from "shared/components";

type TxContractExecutedSectionProps = {
  data: ContactExecutedGql;
  width: any;
  rawData: any;
};

const TxContractExecutedSection = (props: TxContractExecutedSectionProps) => {
  const { data, width, rawData } = props;
  const [smartContractAlias, setSmartContractAlias] = useState<
    string | undefined
  >(undefined);
  const [, setSearchParams] = useSearchParams();
  const { contractTemplates, interpreters, contractInstance } =
    useGlobalIndex();
  useEffect(() => {
    if (rawData && rawData[0]?.callOp?.smart_contract) {
      const data = rawData[0]?.callOp?.smart_contract;
      if (data?.stored && data?.stored[0]?.Claim?.issuer?.FromSmartContract) {
        const hexString = data.stored["0"].Claim.issuer.FromSmartContract;
        const smartContractAlias = checkIssuerAlias(
          hexString,
          interpreters,
          contractTemplates,
          contractInstance
        );
        if (smartContractAlias) setSmartContractAlias(smartContractAlias);
        else setSmartContractAlias(undefined);
      }
    }
  }, [rawData, interpreters, contractTemplates, contractInstance]);
  function RenderTxIdTypography(prop: { txid: string }) {
    return (
      <div className="L2TxId-row" style={{ margin: "5px auto 10px auto" }}>
        <Typography className="BoldFont14 L2TxId-container" align="center">
          {prop.txid ? (
            <>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#4f4f72 !important",
                      padding: "0px !important",
                      marginTop: "-1px !important",
                    },
                  },
                }}
                disableFocusListener
                TransitionComponent={Fade}
                title={<div id="TxId-tooltip">{prop.txid}</div>}
              >
                <div
                  className="L2TxId-container"
                  onClick={() => {
                    setSearchParams({ search: prop.txid });
                  }}
                  id="TxId-container"
                >
                  {handleTxIds(prop.txid)}
                </div>
              </Tooltip>
            </>
          ) : (
            "N/A"
          )}
        </Typography>

        <CopyIcon data={prop.txid} />
        <img
          className="linkArrow"
          src={ActiveSwipe}
          style={{ transform: "rotate(180deg)", marginLeft: "5px" }}
          alt="swipe"
          onClick={() => {
            copyToClipboard(prop.txid);
          }}
        ></img>
      </div>
    );
  }
  function Row(props: { row: any }) {
    const { row } = props;
    const [alias, setAlias] = useState<string | undefined>(undefined);
    const [open, setOpen] = useState(false);
    const [contentOpen, setContentOpen] = useState(false);
    const [issuerOpen, setIssuerOpen] = useState(false);
    const [opsArray, setOpsArray] = useState(() =>
      row && row.length > 0
        ? new Array(row[0]?.ops?.length).fill(false)
        : undefined
    );

    const { interpreters, contractTemplates, contractInstance } =
      useGlobalIndex();

    useEffect(() => {
      if (row && row.length > 0) {
        row[0]?.ops?.forEach((op: any) => {
          const opType = Object.keys(op)[0];
          const opValue = Object.values(op);
          if (opType === "Verified") {
            const argValues = Object.values(opValue[0] ?? opValue);
            const issuerAlias = checkIssuerAlias(
              JSON.stringify(argValues[1]),
              interpreters,
              contractTemplates,
              contractInstance
            );
            if (issuerAlias) setAlias(issuerAlias);
          }
        });
      }
    }, [row, interpreters, contractTemplates, contractInstance]);

    const handleOpsArray = (index: number) => {
      setOpsArray((prevState) => {
        if (prevState) {
          const newArray = [...prevState];
          newArray[index] = !newArray[index];
          return newArray;
        }
      });
    };

    const handleContent = (val: any) => {
      if (val || val[0]) {
        const contentKeys = Object.keys(val[0] ?? val);
        const contentValues = Object.values(val[0] ?? val);
        if (
          contentKeys[0] === "body" &&
          contentKeys[1] === "fees_stored" &&
          contentKeys[2] === "key"
        ) {
          return (
            <div>
              <div className="callOpContainer">
                <Typography className="BoldWhiteFont14">Body</Typography>
                <pre
                  style={{
                    height: "100%",
                    overflow: "scroll",
                    marginBottom: "0px",
                  }}
                >
                  <Typography className="DarkThinFont10 DisplayJsonRaw">
                    {JSON.stringify(contentValues[0], null, 2) ?? "N/A"}
                  </Typography>
                </pre>
              </div>
              <div className="callOpContainer" style={{ marginTop: "5px" }}>
                <Typography className="BoldWhiteFont14">Fees Stored</Typography>
                <Typography className="DarkThinFont10 DisplayJsonRaw">
                  {JSON.stringify(contentValues[1], null, 2) ?? "N/A"}
                </Typography>
              </div>
              <div className="callOpContainer">
                <Typography
                  className="BoldWhiteFont14"
                  sx={{ marginTop: "10px" }}
                >
                  Key
                </Typography>
                <Typography className="DarkThinFont10 DisplayJsonRaw">
                  {JSON.stringify(contentValues[2], null, 2) ?? "N/A"}
                </Typography>
              </div>
            </div>
          );
        }
      }
    };

    return (
      <div style={{ width: "100%" }}>
        <TableCell
          onClick={() => setOpen(!open)}
          className="operationCollapseCell"
        >
          <IconButton aria-label="expand row" size="small">
            {open ? (
              <KeyboardArrowUpIcon sx={{ color: "rgb(0, 195, 255)" }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: "rgb(0, 195, 255)" }} />
            )}
          </IconButton>
          <Typography className="BoldBlueFont16">Operation Details</Typography>
        </TableCell>

        <TableCell className="operationCollapseCell">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              {row &&
                row.length > 0 &&
                opsArray &&
                row[0]?.ops?.map((op: any, index: number) => {
                  const opType = Object.keys(op)[0];
                  const opValue = Object.values(op);

                  if (opType === "Verified") {
                    return (
                      <div style={{ marginTop: "10px" }} key={index}>
                        <TableCell
                          onClick={() => handleOpsArray(index)}
                          className="operationCollapseCell"
                        >
                          <IconButton aria-label="expand row" size="small">
                            {opsArray[index] ? (
                              <KeyboardArrowUpIcon sx={{ color: "#fff" }} />
                            ) : (
                              <KeyboardArrowDownIcon sx={{ color: "#fff" }} />
                            )}
                          </IconButton>
                          <Typography className="BoldWhiteFont14">
                            Forwarded Claim
                          </Typography>
                        </TableCell>
                        <Collapse
                          in={opsArray[index]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <div>
                            <TableCell
                              onClick={() => setContentOpen(!contentOpen)}
                              className="operationCollapseCell"
                            >
                              <IconButton aria-label="expand row" size="small">
                                {contentOpen ? (
                                  <KeyboardArrowUpIcon
                                    sx={{ color: "rgb(0, 195, 255)" }}
                                  />
                                ) : (
                                  <KeyboardArrowDownIcon
                                    sx={{ color: "rgb(0, 195, 255)" }}
                                  />
                                )}
                              </IconButton>
                              <Typography className="BoldBlueFont14">
                                Content
                              </Typography>
                            </TableCell>
                            <Collapse
                              in={contentOpen}
                              timeout="auto"
                              unmountOnExit
                            >
                              {handleContent(opValue[0])}
                            </Collapse>
                          </div>
                          <div>
                            <TableCell
                              onClick={() => setIssuerOpen(!issuerOpen)}
                              className="operationCollapseCell"
                            >
                              <IconButton aria-label="expand row" size="small">
                                {issuerOpen ? (
                                  <KeyboardArrowUpIcon
                                    sx={{ color: "rgb(0, 195, 255)" }}
                                  />
                                ) : (
                                  <KeyboardArrowDownIcon
                                    sx={{ color: "rgb(0, 195, 255)" }}
                                  />
                                )}
                              </IconButton>
                              <Typography className="BoldBlueFont14">
                                Issuer
                              </Typography>
                            </TableCell>
                            <Collapse
                              in={issuerOpen}
                              timeout="auto"
                              unmountOnExit
                            >
                              {alias ? (
                                <Alias alias={alias} />
                              ) : (
                                <Typography className="DarkThinFont10 DisplayJsonRaw">
                                  {JSON.stringify(opValue[1], null, 2)}
                                </Typography>
                              )}
                            </Collapse>
                          </div>
                        </Collapse>
                      </div>
                    );
                  }

                  return (
                    <div style={{ marginTop: "10px" }} key={index}>
                      <TableCell
                        onClick={() => handleOpsArray(index)}
                        className="operationCollapseCell"
                      >
                        <IconButton aria-label="expand row" size="small">
                          {opsArray[index] ? (
                            <KeyboardArrowUpIcon sx={{ color: "#fff" }} />
                          ) : (
                            <KeyboardArrowDownIcon sx={{ color: "#fff" }} />
                          )}
                        </IconButton>
                        <Typography className="BoldWhiteFont14">
                          Argument
                        </Typography>
                      </TableCell>
                      <Collapse
                        in={opsArray[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Typography className="DarkThinFont10 DisplayJsonRaw">
                          {JSON.stringify(opValue, null, 2)}
                        </Typography>
                      </Collapse>
                    </div>
                  );
                })}
            </div>
          </Collapse>
        </TableCell>
      </div>
    );
  }
  return (
    <div
      className="L2txLogCardInfo-container"
      style={{
        marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <div>
        <Typography className="BoldBlueFont16">Contract executed</Typography>
      </div>
      <div id="Carousel" style={{ textIndent: "0px !important" }}>
        <div style={{ width: "100%", height: "100%" }}>
          <div className="ParentsCarousel-inner" style={{ height: "100%" }}>
            <div
              style={{ marginTop: "10px" }}
              className="L2TxLogCard-data-table  L2TxLogInfo-container"
            >
              <div className="gridRightBorder">
                <Typography className="BoldFont16">Details:</Typography>
                {rawData &&
                  rawData?.length > 0 &&
                  rawData[0]?.callOp?.smart_contract && (
                    <div className="callOpContainer">
                      <Typography className="BoldWhiteFont14">
                        smart contract:
                      </Typography>
                      {smartContractAlias ? (
                        <Alias alias={smartContractAlias} />
                      ) : rawData[0]?.callOp?.smart_contract
                          .ComputationBlockContract ? (
                        <RenderTxIdTypography
                          txid={
                            rawData[0]?.callOp?.smart_contract
                              ?.ComputationBlockContract
                          }
                        />
                      ) : (
                        <Typography
                          sx={{ margin: "5px 0px 10px 0px" }}
                          className="BoldFont14"
                        >
                          {JSON.stringify(
                            trimStrings(rawData[0]?.callOp?.smart_contract),
                            null,
                            2
                          )}
                        </Typography>
                      )}
                      {rawData[0]?.callOp?.number_of_arguments && (
                        <div className="callOpContainer">
                          <Typography className="BoldWhiteFont14">
                            number of operations:
                          </Typography>
                          <Typography className="BoldFont14">
                            {rawData[0]?.callOp?.number_of_arguments}
                          </Typography>
                        </div>
                      )}
                      {rawData[0]?.callOp?.resources && (
                        <div className="callOpContainer">
                          <Typography className="BoldWhiteFont14">
                            resources:
                          </Typography>
                          <Typography
                            className="BoldFont14"
                            sx={{ margin: "5px 0px 10px 0px" }}
                          >
                            {rawData[0]?.callOp?.resources}
                          </Typography>
                        </div>
                      )}
                    </div>
                  )}
                <Row row={rawData} />
              </div>
              <div>
                {data?.children?.map((child: any, index: number) => {
                  return (
                    <div key={index}>
                      <Typography className="BoldFont16">
                        Child Tx #{index + 1}
                      </Typography>
                      <RenderTxIdTypography txid={child} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(TxContractExecutedSection);
