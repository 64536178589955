import "./L2TxLogCard.css";
import { memo } from "react";
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { EmbeddedGql } from "../../generated/graphql";
import CopyIcon from "components/CopyIcon";
import { Fade, Tooltip } from "@mui/material";
import { MOBILE_WIDTH } from "components/TableContainer/CustomizedMui";
import handleTxIds from "utils/handleTxIds";
type EmbeddedParentSectionProps = {
  data: EmbeddedGql;
  width: any;
};

const EmbeddedParentSection = (props: EmbeddedParentSectionProps) => {
  const { data, width } = props;

  const [, setSearchParams] = useSearchParams();

  function RenderTxIdTypography(prop: { txid: string }) {
    return (
      <div className="L2TxId-row">
        <Typography className="BoldFont14 L2TxId-container" align="center">
          {prop.txid ? (
            <>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#4f4f72 !important",
                      padding: "0px !important",
                      marginTop: "-1px !important",
                    },
                  },
                }}
                disableFocusListener
                TransitionComponent={Fade}
                title={<div id="TxId-tooltip">{prop.txid}</div>}
              >
                <div
                  className="L2TxId-container"
                  onClick={() => {
                    setSearchParams({ search: prop.txid });
                  }}
                  id="TxId-container"
                >
                  {" "}
                  {handleTxIds(prop.txid)}
                </div>
              </Tooltip>
            </>
          ) : (
            "N/A"
          )}
        </Typography>
        <CopyIcon data={prop.txid} />
      </div>
    );
  }

  return (
    <div
      className="L2txLogCardInfo-container"
      style={{
        marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <div id="Carousel" style={{ textIndent: "0px !important" }}>
        <div style={{ width: "100%", height: "100%" }}>
          <div className="ParentsCarousel-inner" style={{ height: "100%" }}>
            <div className="ParentsCenter-container">
              <div>
                <Typography className="BoldFont16">
                  Embedded at L1 Txid
                </Typography>
                <RenderTxIdTypography txid={data.l1Txs[0]} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(EmbeddedParentSection);
