import CwebIcon from "../assets/Icons/Networks/cweb.png";
import BtcIcon from "../assets/Icons/Networks/bitcoin.svg";
import BtcCashIcon from "../assets/Icons/Networks/bitcoin-cash.png";
import LiteIcon from "../assets/Icons/Networks/lite.svg";
import EthIcon from "../assets/Icons/Networks/ethereum.svg";
import EgldIcon from "../assets/Icons/Networks/elrond.svg";
import PolyIcon from "../assets/Icons/Networks/polygon.svg";
import Doge from "../assets/Icons/Networks/doge.svg";
import BNBIcon from "../assets/Icons/Networks/bnb.png";
import KujiIcon from "../assets/Icons/Networks/kuji.png";
import ArbIcon from "../assets/Icons/Networks/arb.png";
import { NetworkName } from "generated/graphql";
import { NetworkName as devNetworkName } from "generated/graphql-devnet";
import { BlockChain } from "components/TableContainer/CustomizedMui";
import {
  NetworkName as FetchNetwork 
} from "@coinweb/claims-client";
export const BlockChains: {
  [key in devNetworkName | NetworkName]: BlockChain;
} = {
  BITCOIN: {
    networkName: NetworkName.Bitcoin,
    label: "BTC",
    hover: "Bitcoin",
    currency: "BTC",
    icon: BtcIcon,
    fetchNetwork: FetchNetwork.Bitcoin,
  },
  BITCOIN_CASH: {
    networkName: NetworkName.BitcoinCash,
    label: "BCH",
    hover: "Bitcoin Cash",
    currency: "BCH",
    icon: BtcCashIcon,
    fetchNetwork: FetchNetwork.BitcoinCash,
  },
  BITCOIN_CASH_TESTNET: {
    networkName: NetworkName.BitcoinCashTestnet,
    label: "tBCH",
    hover: "Bitcoin Cash Testnet",
    currency: "BCH",
    icon: BtcCashIcon,
    fetchNetwork: FetchNetwork.BitcoinCashTestnet,

  },
  BITCOIN_TESTNET: {
    networkName: NetworkName.BitcoinTestnet,
    label: "tBTC",
    hover: "Bitcoin Testnet",
    currency: "BTC",
    icon: BtcIcon,
    fetchNetwork: FetchNetwork.BitcoinTestnet,

  },
  LITECOIN: {
    networkName: NetworkName.Litecoin,
    label: "LTC",
    hover: "Litecoin",
    currency: "LTC",
    icon: LiteIcon,
    fetchNetwork: FetchNetwork.Litecoin,

  },
  LITECOIN_TESTNET: {
    networkName: NetworkName.LitecoinTestnet,
    label: "tLTC",
    hover: "Litecoin Testnet",
    currency: "LTC",
    icon: LiteIcon,
    fetchNetwork: FetchNetwork.LitecoinTestnet,

  },
  DOGECOIN: {
    networkName: NetworkName.Dogecoin,
    label: "DOGE",
    hover: "Dogecoin",
    currency: "DOGE",
    icon: Doge,
    fetchNetwork: FetchNetwork.Dogecoin,

  },
  DOGECOIN_TESTNET: {
    networkName: NetworkName.DogecoinTestnet,
    label: "tDOGE",
    hover: "Dogecoin Testnet",
    currency: "DOGE",
    icon: Doge,
    fetchNetwork: FetchNetwork.DogecoinTestnet,

  },
  ETHEREUM: {
    networkName: NetworkName.Ethereum,
    label: "ETH",
    hover: "Ethereum",
    currency: "ETH",
    icon: EthIcon,
    fetchNetwork: FetchNetwork.Ethereum,

  },
  ETHEREUM_TESTNET: {
    networkName: NetworkName.EthereumTestnet,
    label: "tETH",
    hover: "Ethereum Testnet",
    currency: "ETH",
    icon: EthIcon,
    fetchNetwork: FetchNetwork.EthereumTestnet,

  },

  ELROND: {
    networkName: NetworkName.Elrond,
    label: "EGLD",
    hover: "MultiversX",
    currency: "EGLD",
    icon: EgldIcon,
    fetchNetwork: FetchNetwork.Elrond,

  },
  ELROND_TESTNET: {
    networkName: NetworkName.ElrondTestnet,
    label: "tEGLD",
    hover: "MultiversX Testnet",
    currency: "BCH",
    icon: EgldIcon,
    fetchNetwork: FetchNetwork.ElrondTestnet,

  },
  BNB: {
    networkName: NetworkName.Bnb,
    label: "BNB",
    hover: "Binance",
    currency: "BNB",
    icon: BNBIcon,
    fetchNetwork: FetchNetwork.Bnb,

  },
  BNB_TESTNET: {
    networkName: NetworkName.BnbTestnet,
    label: "tBNB",
    hover: "Binance Testnet",
    currency: "BNB",
    icon: BNBIcon,
    fetchNetwork: FetchNetwork.BnbTestnet,

  },
  POLYGON: {
    networkName: NetworkName.Polygon,
    label: "MATIC",
    hover: "Polygon",
    currency: "MATIC",
    icon: PolyIcon,
    fetchNetwork: FetchNetwork.Polygon,

  },
  POLYGON_TESTNET: {
    networkName: NetworkName.PolygonTestnet,
    label: "tMATIC",
    hover: "Polygon Testnet",
    currency: "MATIC",
    icon: PolyIcon,
    fetchNetwork: FetchNetwork.PolygonTestnet,

  },
  KUJI: {
    networkName: NetworkName.Kuji,
    label: "KUJI",
    hover: "Kujira",
    currency: "KUJI",
    icon: KujiIcon,
    fetchNetwork: FetchNetwork.Kuji,

  },
  KUJI_TESTNET: {
    networkName: NetworkName.KujiTestnet,
    label: "tKUJI",
    hover: "Kujira Testnet",
    currency: "KUJI",
    icon: KujiIcon,
    fetchNetwork: FetchNetwork.KujiTestnet,

  },
  ARBITRUM: {
    networkName: NetworkName.Arbitrum,
    label: "ARB",
    hover: "Arbitrum",
    currency: "ARB",
    icon: ArbIcon,
    fetchNetwork: FetchNetwork.Arbitrum,

  },
  ARBITRUM_TESTNET: {
    networkName: NetworkName.ArbitrumTestnet,
    label: "tARB",
    hover: "Arbitrum Testnet",
    currency: "ARB",
    icon: ArbIcon,
    fetchNetwork: FetchNetwork.ArbitrumTestnet,

  },
  DEVNET_L1A: {
    networkName: devNetworkName.DevnetL1A,
    label: "devnetA",
    hover: "Devnet A",
    currency: "Cweb",
    icon: CwebIcon,
    fetchNetwork: FetchNetwork.DevnetL1A,

  },
  DEVNET_L1B: {
    networkName: devNetworkName.DevnetL1B,
    label: "devnetB",
    hover: "Devnet B",
    currency: "Cweb",
    icon: CwebIcon,
    fetchNetwork: FetchNetwork.DevnetL1B,

  },
};
